exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-details-js": () => import("./../../../src/pages/account-details.js" /* webpackChunkName: "component---src-pages-account-details-js" */),
  "component---src-pages-business-index-js": () => import("./../../../src/pages/business/index.js" /* webpackChunkName: "component---src-pages-business-index-js" */),
  "component---src-pages-business-onboarding-index-js": () => import("./../../../src/pages/business/onboarding/index.js" /* webpackChunkName: "component---src-pages-business-onboarding-index-js" */),
  "component---src-pages-business-products-and-solutions-2-moorch-business-js": () => import("./../../../src/pages/business/products-and-solutions/2-moorch-business.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-2-moorch-business-js" */),
  "component---src-pages-business-products-and-solutions-business-broadband-js": () => import("./../../../src/pages/business/products-and-solutions/business-broadband.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-business-broadband-js" */),
  "component---src-pages-business-products-and-solutions-index-jsx": () => import("./../../../src/pages/business/products-and-solutions/index.jsx" /* webpackChunkName: "component---src-pages-business-products-and-solutions-index-jsx" */),
  "component---src-pages-business-products-and-solutions-one-business-js": () => import("./../../../src/pages/business/products-and-solutions/one-business.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-one-business-js" */),
  "component---src-pages-business-products-and-solutions-your-business-online-index-js": () => import("./../../../src/pages/business/products-and-solutions/your-business-online/index.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-your-business-online-index-js" */),
  "component---src-pages-business-products-and-solutions-your-business-online-register-js": () => import("./../../../src/pages/business/products-and-solutions/your-business-online/register.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-your-business-online-register-js" */),
  "component---src-pages-business-wholesale-cross-border-connectivity-js": () => import("./../../../src/pages/business/wholesale/cross-border-connectivity.js" /* webpackChunkName: "component---src-pages-business-wholesale-cross-border-connectivity-js" */),
  "component---src-pages-business-wholesale-dedicated-internet-js": () => import("./../../../src/pages/business/wholesale/dedicated-internet.js" /* webpackChunkName: "component---src-pages-business-wholesale-dedicated-internet-js" */),
  "component---src-pages-business-wholesale-index-js": () => import("./../../../src/pages/business/wholesale/index.js" /* webpackChunkName: "component---src-pages-business-wholesale-index-js" */),
  "component---src-pages-business-wholesale-iplc-js": () => import("./../../../src/pages/business/wholesale/iplc.js" /* webpackChunkName: "component---src-pages-business-wholesale-iplc-js" */),
  "component---src-pages-business-wholesale-wide-area-network-js": () => import("./../../../src/pages/business/wholesale/wide-area-network.js" /* webpackChunkName: "component---src-pages-business-wholesale-wide-area-network-js" */),
  "component---src-pages-cdr-request-js": () => import("./../../../src/pages/cdr-request.js" /* webpackChunkName: "component---src-pages-cdr-request-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-data-sharing-history-js": () => import("./../../../src/pages/data-sharing-history.js" /* webpackChunkName: "component---src-pages-data-sharing-history-js" */),
  "component---src-pages-data-usage-js": () => import("./../../../src/pages/data-usage.js" /* webpackChunkName: "component---src-pages-data-usage-js" */),
  "component---src-pages-dstv-index-js": () => import("./../../../src/pages/dstv/index.js" /* webpackChunkName: "component---src-pages-dstv-index-js" */),
  "component---src-pages-ecg-index-jsx": () => import("./../../../src/pages/ecg/index.jsx" /* webpackChunkName: "component---src-pages-ecg-index-jsx" */),
  "component---src-pages-ecg-receipt-jsx": () => import("./../../../src/pages/ecg/receipt.jsx" /* webpackChunkName: "component---src-pages-ecg-receipt-jsx" */),
  "component---src-pages-enterprise-account-balance-jsx": () => import("./../../../src/pages/enterprise/account-balance.jsx" /* webpackChunkName: "component---src-pages-enterprise-account-balance-jsx" */),
  "component---src-pages-enterprise-account-statement-jsx": () => import("./../../../src/pages/enterprise/account-statement.jsx" /* webpackChunkName: "component---src-pages-enterprise-account-statement-jsx" */),
  "component---src-pages-enterprise-index-jsx": () => import("./../../../src/pages/enterprise/index.jsx" /* webpackChunkName: "component---src-pages-enterprise-index-jsx" */),
  "component---src-pages-enterprise-payment-history-jsx": () => import("./../../../src/pages/enterprise/payment-history.jsx" /* webpackChunkName: "component---src-pages-enterprise-payment-history-jsx" */),
  "component---src-pages-enterprise-query-bill-jsx": () => import("./../../../src/pages/enterprise/query-bill.jsx" /* webpackChunkName: "component---src-pages-enterprise-query-bill-jsx" */),
  "component---src-pages-explore-telecel-executive-team-js": () => import("./../../../src/pages/explore-telecel/executive-team.js" /* webpackChunkName: "component---src-pages-explore-telecel-executive-team-js" */),
  "component---src-pages-explore-telecel-index-js": () => import("./../../../src/pages/explore-telecel/index.js" /* webpackChunkName: "component---src-pages-explore-telecel-index-js" */),
  "component---src-pages-faults-reporting-js": () => import("./../../../src/pages/faults/reporting.js" /* webpackChunkName: "component---src-pages-faults-reporting-js" */),
  "component---src-pages-faults-tracking-js": () => import("./../../../src/pages/faults/tracking.js" /* webpackChunkName: "component---src-pages-faults-tracking-js" */),
  "component---src-pages-fixed-data-sharing-js": () => import("./../../../src/pages/fixed/data-sharing.js" /* webpackChunkName: "component---src-pages-fixed-data-sharing-js" */),
  "component---src-pages-generate-receipt-js": () => import("./../../../src/pages/generate-receipt.js" /* webpackChunkName: "component---src-pages-generate-receipt-js" */),
  "component---src-pages-ghana-water-company-index-js": () => import("./../../../src/pages/ghana-water-company/index.js" /* webpackChunkName: "component---src-pages-ghana-water-company-index-js" */),
  "component---src-pages-ghana-water-company-receipt-js": () => import("./../../../src/pages/ghana-water-company/receipt.js" /* webpackChunkName: "component---src-pages-ghana-water-company-receipt-js" */),
  "component---src-pages-index-cw-js": () => import("./../../../src/pages/index-cw.js" /* webpackChunkName: "component---src-pages-index-cw-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-puk-js": () => import("./../../../src/pages/mobile/puk.js" /* webpackChunkName: "component---src-pages-mobile-puk-js" */),
  "component---src-pages-more-money-js": () => import("./../../../src/pages/more-money.js" /* webpackChunkName: "component---src-pages-more-money-js" */),
  "component---src-pages-pay-index-js": () => import("./../../../src/pages/pay/index.js" /* webpackChunkName: "component---src-pages-pay-index-js" */),
  "component---src-pages-pay-receipt-js": () => import("./../../../src/pages/pay/receipt.js" /* webpackChunkName: "component---src-pages-pay-receipt-js" */),
  "component---src-pages-personal-broadband-application-jsx": () => import("./../../../src/pages/personal/broadband/application.jsx" /* webpackChunkName: "component---src-pages-personal-broadband-application-jsx" */),
  "component---src-pages-personal-broadband-business-application-js": () => import("./../../../src/pages/personal/broadband/business-application.js" /* webpackChunkName: "component---src-pages-personal-broadband-business-application-js" */),
  "component---src-pages-personal-broadband-index-jsx": () => import("./../../../src/pages/personal/broadband/index.jsx" /* webpackChunkName: "component---src-pages-personal-broadband-index-jsx" */),
  "component---src-pages-personal-broadband-offers-bundles-js": () => import("./../../../src/pages/personal/broadband/offers/bundles.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-bundles-js" */),
  "component---src-pages-personal-broadband-offers-flexi-offers-js": () => import("./../../../src/pages/personal/broadband/offers/flexi-offers.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-flexi-offers-js" */),
  "component---src-pages-personal-broadband-offers-one-family-js": () => import("./../../../src/pages/personal/broadband/offers/one-family.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-one-family-js" */),
  "component---src-pages-personal-broadband-offers-special-offers-and-promotions-js": () => import("./../../../src/pages/personal/broadband/offers/special-offers-and-promotions.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-special-offers-and-promotions-js" */),
  "component---src-pages-personal-broadband-offers-standard-bundles-js": () => import("./../../../src/pages/personal/broadband/offers/standard-bundles.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-standard-bundles-js" */),
  "component---src-pages-personal-broadband-promos-2-moorch-family-offer-js": () => import("./../../../src/pages/personal/broadband/promos/2-moorch-family-offer.js" /* webpackChunkName: "component---src-pages-personal-broadband-promos-2-moorch-family-offer-js" */),
  "component---src-pages-personal-broadband-relocation-jsx": () => import("./../../../src/pages/personal/broadband/relocation.jsx" /* webpackChunkName: "component---src-pages-personal-broadband-relocation-jsx" */),
  "component---src-pages-personal-mobile-index-jsx": () => import("./../../../src/pages/personal/mobile/index.jsx" /* webpackChunkName: "component---src-pages-personal-mobile-index-jsx" */),
  "component---src-pages-personal-mobile-offers-bundles-js": () => import("./../../../src/pages/personal/mobile/offers/bundles.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-bundles-js" */),
  "component---src-pages-personal-mobile-offers-tariffs-js": () => import("./../../../src/pages/personal/mobile/offers/tariffs.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-tariffs-js" */),
  "component---src-pages-personal-mobile-offers-telecel-black-apply-js": () => import("./../../../src/pages/personal/mobile/offers/telecel-black/apply.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-telecel-black-apply-js" */),
  "component---src-pages-personal-mobile-offers-telecel-black-index-js": () => import("./../../../src/pages/personal/mobile/offers/telecel-black/index.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-telecel-black-index-js" */),
  "component---src-pages-personal-mobile-offers-top-up-js": () => import("./../../../src/pages/personal/mobile/offers/top-up.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-top-up-js" */),
  "component---src-pages-personal-mobile-shop-connected-devices-js": () => import("./../../../src/pages/personal/mobile/shop/connected-devices.js" /* webpackChunkName: "component---src-pages-personal-mobile-shop-connected-devices-js" */),
  "component---src-pages-personal-mobile-shop-devices-js": () => import("./../../../src/pages/personal/mobile/shop/devices.js" /* webpackChunkName: "component---src-pages-personal-mobile-shop-devices-js" */),
  "component---src-pages-personal-telecel-cash-index-jsx": () => import("./../../../src/pages/personal/telecel-cash/index.jsx" /* webpackChunkName: "component---src-pages-personal-telecel-cash-index-jsx" */),
  "component---src-pages-personal-telecel-cash-international-money-transfer-js": () => import("./../../../src/pages/personal/telecel-cash/international-money-transfer.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-international-money-transfer-js" */),
  "component---src-pages-personal-telecel-cash-merchant-registration-js": () => import("./../../../src/pages/personal/telecel-cash/merchant-registration.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-merchant-registration-js" */),
  "component---src-pages-personal-telecel-cash-mobile-money-interoperability-js": () => import("./../../../src/pages/personal/telecel-cash/mobile-money-interoperability.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-mobile-money-interoperability-js" */),
  "component---src-pages-personal-telecel-cash-ready-loan-js": () => import("./../../../src/pages/personal/telecel-cash/ready-loan.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-ready-loan-js" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-reset-password-jsx": () => import("./../../../src/pages/reset-password.jsx" /* webpackChunkName: "component---src-pages-reset-password-jsx" */),
  "component---src-pages-standing-order-js": () => import("./../../../src/pages/standing-order.js" /* webpackChunkName: "component---src-pages-standing-order-js" */),
  "component---src-pages-tests-js": () => import("./../../../src/pages/tests.js" /* webpackChunkName: "component---src-pages-tests-js" */),
  "component---src-pages-track-call-detail-records-js": () => import("./../../../src/pages/track-call-detail-records.js" /* webpackChunkName: "component---src-pages-track-call-detail-records-js" */)
}

